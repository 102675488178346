<script setup lang="ts">
import { useStopListStore } from '~/stores/stopList'

const stopListStore = useStopListStore()

onMounted(() => {
  stopListStore.getCartStopList()
})
</script>

<template></template>

<style scoped lang="sass"></style>
